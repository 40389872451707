import { navigate } from "gatsby"
import React from "react"
import Layout from "../../components/core/Layout"
import SEO from "../../components/core/SEO"
import Images from "../../components/Images"

const GalleryPage: React.FC = () => (
  <Layout activePage="gallery">
    <SEO title="Gallery" />
    <h1 className="page-title">Gallery</h1>
    <div className="divider divider--spacer-bottom" />
    <div className="gallery-card__container">
      <div className="gallery-card" onClick={() => navigate("/gallery/mark")}>
        <Images image="markgallery" />
        <h2 className="gallery-card__text">Mark's Work</h2>
      </div>
      <div className="gallery-card" onClick={() => navigate("/gallery/jo")}>
        <Images image="jogallery" />
        <h2 className="gallery-card__text">Jo's Work</h2>
      </div>
      <div
        className="gallery-card"
        onClick={() => navigate("/gallery/sculptural-work")}
      >
        <Images image="sculpturegallery" />
        <h2 className="gallery-card__text">Sculptural Work</h2>
      </div>
    </div>
  </Layout>
)

export default GalleryPage
